/* 
    This CSS file was created by OwL for use by pointer.js, 
     witch can be found on https://seattleowl.com. 
*/

#pointer-dot {
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    padding: 10px;
    background: #7EB6ED;
    border: solid 1px #fff;
    position: fixed;
    border-radius: 20px;
    z-index: 4000;
    pointer-events: none;
    transition: border-color 0.5s;
}

#pointer-ring {
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    padding: 15px;
    border: 2px solid #750c7e;
    position: fixed;
    border-radius: 100px;
    z-index: 4000;
    pointer-events: none;
}

html {
    cursor: none !important;
}

a {
    cursor: none !important;
}
